var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.events.length)+" "+_vm._s(_vm.EventsTitle)+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',[_c('v-list',_vm._l((_vm.events),function(event){return _c('div',{key:event.id},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(event.name)+" "),(
                      !event.is_accepted &&
                        _vm.$store.getters.profile.id != event.from_user.id &&
                        _vm.employee
                    )?_c('v-btn',{attrs:{"color":"success","small":"","text":""},on:{"click":function($event){return _vm.AcceptTask(event)}}},[_vm._v("принимать")]):_vm._e(),(event.status === 'current_task' && _vm.employee && _vm.$store.getters.profile.id !== event.from_user.id)?_c('v-btn',{attrs:{"color":"success","small":"","text":""},on:{"click":function($event){_vm.completeDialog = true}}},[_vm._v("завершать")]):_vm._e(),_c('v-spacer')],1),_c('div',{staticClass:"text--secondary"},[(
                      _vm.$store.getters.profile.id != event.from_user.id &&
                        _vm.employee
                    )?_c('span',[_vm._v(" от "+_vm._s(event.from_user.first_name)+" ")]):_c('span',[_vm._v("Мое задание")]),_c('p',{staticClass:"mt-3 text--primary"},[_vm._v("Подробности")]),_c('truncate',{attrs:{"clamp":"Показать больше","length":80,"less":"Показать меньше","type":"html","text":event.details}})],1)],1)],1),_c('v-divider')],1)}),0)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.completeDialog),callback:function ($$v) {_vm.completeDialog=$$v},expression:"completeDialog"}},[_c('CommentForm',{attrs:{"task":_vm.selectedEvent}})],1),_c('v-snackbar',{staticClass:"snackbar",attrs:{"timeout":"2000","absolute":"","right":"","top":"","color":"green","elevation":"6","multi-line":"","tile":"","transition":"slide-x-reverse-transition"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }