<template>
  <div>
    <v-dialog scrollable v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title
          >{{ events.length }} {{ EventsTitle }} <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-list>
            <div v-for="event in events" v-bind:key="event.id">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ event.name }}
                    <v-btn
                      color="success"
                      @click="AcceptTask(event)"
                      v-if="
                        !event.is_accepted &&
                          $store.getters.profile.id != event.from_user.id &&
                          employee
                      "
                      small
                      text
                      >принимать</v-btn
                    >
                    <v-btn
                      color="success"
                      @click="completeDialog = true"
                      v-if="event.status === 'current_task' && employee && $store.getters.profile.id !== event.from_user.id"
                      small
                      text
                      >завершать</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-list-item-title>
                  <div class="text--secondary">
                    <span
                      v-if="
                        $store.getters.profile.id != event.from_user.id &&
                          employee
                      "
                    >
                      от {{ event.from_user.first_name }}
                    </span>
                    <span v-else>Мое задание</span>

                    <p class="mt-3 text--primary">Подробности</p>
                    <truncate
                      clamp="Показать больше"
                      :length="80"
                      less="Показать меньше"
                      type="html"
                      :text="event.details"
                    ></truncate>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="completeDialog">
      <CommentForm :task="selectedEvent" />
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import { INDIVIDUAL_TASK_PUT } from "@/store/actions/task";
import { NOTIFICATION_POST } from "@/store/actions/notification";

export default {
  name: "tasklist",
  props: {
    events: [],
    type: "",
    dialog: false,
    EventsTitle: "",
    employee: true,
  },

  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      completeDialog: false,
    };
  },

  created() {
    this.$root.$refs.TaskList = this;
  },

  components: {
    CommentForm: () =>
      import(
        /* webpackChunkName: "calendar" */
        "@/components/CommentForm"
      ),
  },

  methods: {
    async AcceptTask(event) {
      let resp = await this.$dispatch(INDIVIDUAL_TASK_PUT, {
        id: event.id,
        is_accepted: true,
        status: "current_task",
      });
      if (resp.success) {
        let resp2 = await this.$dispatch(NOTIFICATION_POST, {
          from_user: this.$store.getters.profile.id,
          to_users: [event.from_user.id],
          individual_task: event.id,
          type: "accepted_task",
        });
        this.snackbar_text = "Задача перемещена в категорию текущих задач";
        this.snackbar = true;
        this.$root.$refs.Dashboard.getNumberOfTasks();
        this.$root.$refs.Dashboard.getNewTasks();
      } else {
        event.is_accepted = false;
      }
    },
  },
};
</script>

<style></style>
