<template>
  <v-card
    @click="click"
    class="card"
    active-class="active"
    rounded="xl"
    min-height="120px"
    max-height="420px"
    elevation="6"
  >
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card-heading"
    ></v-skeleton-loader>
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card-heading"
    ></v-skeleton-loader>
    <div v-if="!loading" class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          class="text-subtitle-1 font-weight-bold"
          v-text="title"
        ></v-card-title>

        <v-card-text class="text-h4" v-text="amount"></v-card-text>
      </div>

      <v-icon class="ma-3" size="96" :color="color">{{ icon }}</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "card-widget",
  props: {
    color: "",
    title: "",
    icon: "",
    amount: "",
    click: null,
    loading: true,
  },
};
</script>

<style lang="scss" scoped>
.card::before {
  background: none !important;
}
</style>>
