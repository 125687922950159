<template>
  <div>
    <v-toolbar class="mb-2" flat>
      <v-toolbar-title class="text-h4 success--text"
        >{{ $store.getters.profile.first_name }}
        {{ $store.getters.profile.last_name }}</v-toolbar-title
      >
    </v-toolbar>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <card-widget
          title="Новые задачи"
          :click="getNewTasks"
          icon="mdi-new-box"
          :amount="new_tasks"
          color="success"
          :loading="x_loading"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <card-widget
          title="Текущие задачи"
          :click="getCurrentTasks"
          icon="mdi-table-check"
          :amount="current_tasks"
          color="primary"
          :loading="x_loading"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <card-widget
          title="Мои задачи"
          :click="getPersonalTasks"
          icon="mdi-account"
          color="info"
          :amount="my_tasks"
          :loading="x_loading"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
        <card-widget
          title="Задачи, поставленные мной"
          :click="getGivenTasks"
          icon="mdi-new-box"
          :amount="by_me"
          color="success"
          :loading="x_loading"
        />
      </v-col>
    </v-row>
    <tasklist
      :events="events"
      :EventsTitle="EventsTitle"
      :dialog="dialog"
      :employee="true"
    ></tasklist>
  </div>
</template>

<script>
import { INDIVIDUAL_TASKS_GET } from "@/store/actions/task";
export default {
  data() {
    return {
      events: [],
      dialog: false,
      EventsTitle: "",
      new_tasks: 0,
      current_tasks: 0,
      my_tasks: 0,
      by_me: 0
    };
  },

  components: {
    CardWidget: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/components/widgets/CardWidget"
      ),
    tasklist: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/components/TaskList"
      ),
  },
  methods: {
    async getNewTasks() {
      let resp = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&status=new_task`
      );
      this.events = resp.result;
      this.EventsTitle = "Новые задачи";
      this.dialog = true;
    },
    async getGivenTasks() {
      let resp = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?from_user=${this.$store.getters.profile.id}`
      );
      this.events = resp.result;
      this.EventsTitle = "Задачи, поставленные мной";
      this.dialog = true;
    },
    async getCurrentTasks() {
      let resp = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&status=current_task`
      );
      this.events = resp.result;
      this.EventsTitle = "Текущие задачи";
      this.dialog = true;
    },
    async getPersonalTasks() {
      let resp = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&from_user=${this.$store.getters.profile.id}`
      );
      this.events = resp.result;
      this.EventsTitle = "Мои задачи";
      this.dialog = true;
    },
    async getNumberOfTasks() {
      let resp = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&status=new_task`
      );
      this.new_tasks = resp.result.length;
      let resp2 = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&status=current_task`
      );
      this.current_tasks = resp2.result.length;
      let resp3 = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?to_users=${this.$store.getters.profile.id}&from_user=${this.$store.getters.profile.id}`
      );
      this.my_tasks = resp3.result.length;
      let resp4 = await this.$dispatch(
        INDIVIDUAL_TASKS_GET,
        `?from_user=${this.$store.getters.profile.id}`
      );
      this.by_me = resp4.result.length;
    },
  },
  async created() {
    this.getNumberOfTasks();
    this.$root.$refs.Dashboard = this;
  },
};
</script>

<style>
</style>